import React from 'react';

import PageMain from './pages/main';

export const Client = () => (
  <main className="main row">
    {
      window.scrollTo(0, 0)
    }
    <PageMain />
  </main>
);
