import React from 'react';

import close from '../../assets/icon_close.svg';

export const Modal = ({ children, toggleModal, open }) => {
  const handleClose = (e) => {
    if (Array.from(e.target.classList).includes('modal-wrapper')) {
      toggleModal(false);
    }
  };

  return (
    <div
      className={`modal-wrapper ${open ? 'active' : ''}`}
      aria-hidden
      onClick={handleClose}
    >
      <div className="modal column">
        <img
          className="pointer"
          src={close}
          alt=""
          aria-hidden
          onClick={() => toggleModal(false)}
        />
        {children}
      </div>
    </div>
  );
};
