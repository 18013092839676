const url = 'https://us-central1-ba-mail-sender.cloudfunctions.net/sendMail';

export async function sendMail(data) {
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const json = await response.json();
    console.log('Resp:', JSON.stringify(json));
    return json;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
}
