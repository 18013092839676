import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

import { sendMail } from '../services/api';
import { Spinner } from './spinner';
import SectionTitle from './section-title';

const Checkbox = ({ label, value = false, onChange }) => (
  <div
    className="checkbox-wrapper flex pointer"
    role="button"
    aria-hidden
    onClick={() => onChange({ target: { value: !value } })}
  >
    <p className="label">{label}</p>
    <span className={classNames('checkbox', { active: value })} />
  </div>
);

const Field = ({
  label, children, error = { show: false, message: '' }, className = 'row'
}) => (
  <div className="column m-t-10">
    <div className={classNames('field-wrapper', className)}>
      <p className="label">{label}</p>
      {
        children
      }
    </div>
    {
      error.show && <p className="error">{error.message}</p>
    }
  </div>
);

const Form = ({ t }) => {
  const [showMessage, toggleMessage] = useState(false);
  const [showSpinner, toggleSpinner] = useState(false);
  const [form, changeForm] = useState({
    name: '',
    surname: '',
    phone: '',
    detailsList: '',
    brand: '',
    model: '',
    year: '',
    vin: ''
  });
  const [nameError, toggleNameError] = useState(false);
  const [surnameError, toggleSurnameError] = useState(false);
  const [phoneError, togglePhoneError] = useState(false);
  const [detailsListError, toggleDetailsListError] = useState(false);
  const [brandError, toggleBrandError] = useState(false);
  const [modelError, toggleModelError] = useState(false);
  const [yearError, toggleYearError] = useState(false);
  const [vinError, toggleVINError] = useState(false);

  const setFormValue = (key) => (e) => changeForm({ ...form, [key]: e.target.value });

  const validate = (value, len) => value < len;

  const send = async () => {
    const isInvalidName = validate(form.name.length, 2);
    const isInvalidSurname = validate(form.surname.length, 2);
    const isInvalidPhone = validate(form.phone.length, 19);
    const isInvalidDetailsList = validate(form.detailsList.length, 2);
    const isInvalidBrand = validate(form.brand.length, 2);
    const isInvalidModel = validate(form.model.length, 2);
    const isInvalidYear = validate(form.year.length, 4);
    const isInvalidVIN = validate(form.vin.length, 17);

    toggleNameError(isInvalidName);
    toggleSurnameError(isInvalidSurname);
    togglePhoneError(isInvalidPhone);
    toggleDetailsListError(isInvalidDetailsList);
    toggleBrandError(isInvalidBrand);
    toggleModelError(isInvalidModel);
    toggleYearError(isInvalidYear);
    toggleVINError(isInvalidVIN);

    if (
      !isInvalidName && !isInvalidSurname && !isInvalidPhone && !isInvalidDetailsList
      && !isInvalidBrand && !isInvalidModel && !isInvalidYear && !isInvalidVIN
    ) {
      const data = {
        form,
        // to: 'semenyuk.c.s@gmail.com'
        to: 'olehmaksym@gmail.com',
        subject: 'Заявка з сайту Defektovka'
      };

      toggleSpinner(true);
      const res = await sendMail(data);
      if (res) {
        toggleMessage(true);
        toggleSpinner(false);
      }
    }
  };

  return showMessage
    ? (
      <div className="application-form column">
        <p className="success-message">{t('mailSended')}</p>
      </div>
    )
    : (
      <div className="application-form column">
        <SectionTitle title="forms.label" />
        <div className="checkbox-container row">
          <Checkbox
            label={t('forms.state.new')}
            value={form.stateNew}
            onChange={setFormValue('stateNew')}
          />
          <Checkbox
            label={t('forms.state.used')}
            value={form.stateUsed}
            onChange={setFormValue('stateUsed')}
          />
          <Checkbox
            label={t('forms.state.changed')}
            value={form.stateChanged}
            onChange={setFormValue('stateChanged')}
          />
        </div>
        <Field
          className="row m-t-20"
          label={t('forms.brand')}
          error={{
            show: brandError,
            message: t('forms.errors.brand')
          }}
        >
          <input
            type="text"
            value={form.brand}
            onChange={setFormValue('brand')}
          />
        </Field>
        <Field
          label={t('forms.model')}
          error={{
            show: modelError,
            message: t('forms.errors.model')
          }}
        >
          <input
            type="text"
            value={form.model}
            onChange={setFormValue('model')}
          />
        </Field>
        <Field
          label={t('forms.year')}
          error={{
            show: yearError,
            message: t('forms.errors.year')
          }}
        >
          <InputMask
            mask="9999"
            maskChar=""
            value={form.year}
            onChange={setFormValue('year')}
          />
        </Field>
        <Field
          label={t('forms.vin')}
          error={{
            show: vinError,
            message: t('forms.errors.vin')
          }}
        >
          <input
            type="text"
            value={form.vin}
            onChange={setFormValue('vin')}
          />
        </Field>
        <Field
          className="column"
          label={t('forms.detailsList')}
          error={{
            show: detailsListError,
            message: t('forms.errors.detailsList')
          }}
        >
          <textarea
            value={form.detailsList}
            onChange={setFormValue('detailsList')}
          />
        </Field>
        <Field
          className="inline row"
          label={t('forms.name')}
          error={{
            show: nameError,
            message: t('forms.errors.name')
          }}
        >
          <input
            type="text"
            value={form.name}
            onChange={setFormValue('name')}
          />
        </Field>
        <Field
          className="inline row"
          label={t('forms.surname')}
          error={{
            show: surnameError,
            message: t('forms.errors.surname')
          }}
        >
          <input
            type="text"
            value={form.surname}
            onChange={setFormValue('surname')}
          />
        </Field>
        <Field
          className="inline row"
          label={t('forms.phone')}
          error={{
            show: phoneError,
            message: t('forms.errors.phone')
          }}
        >
          <InputMask
            mask="+38 (099) 999 99 99"
            value={form.phone}
            maskChar=""
            onChange={setFormValue('phone')}
          />
        </Field>
        <button type="button" className="button secondary m-t-20" onClick={send}>{t('forms.send')}</button>
        {
          showSpinner && (
            <div className="spinner-wrapper">
              <Spinner />
            </div>
          )
        }
      </div>
    );
};

export default withTranslation()(Form);
