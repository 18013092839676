import React from 'react';
import LOGO from '../../assets/img/logo.svg';
// import PLANET from '../../assets/img/planet.svg';
import { LangSwitcher } from './lang-switcher';

const NavigationBar = () => ((
  <nav className="navigation-bar">
    <img className="logo" src={LOGO} alt="defektovka" />
    <div className="languages">
      {/* //TODO: Add list for mobile or remove it */}
      {/* <img src={PLANET} alt="language" /> */}
      <LangSwitcher />
    </div>
  </nav>
));

export default NavigationBar;
