
import React from 'react';
import ARROW_DOWN_SVG from '../../assets/img/arrow-down.svg';

const Accordion = (props) => {
  const {
    id,
    title,
    activeElementId,
    setActiveList,
    children
  } = props;
  const menuList = document.querySelector(`#${id} .menu-list`);
  const isIDEqual = activeElementId === id;
  let listHeight = 0;

  if (menuList) {
    const items = [...menuList.children];
    const menuListHeight = items.reduce((a, b) => a + b.clientHeight, 0);

    listHeight = `${menuListHeight}px`;
    menuList.style.height = listHeight;
    items.forEach((el, i) => {
      el.style.animationDelay = `.${i + 1}s`;
    });
  }

  if (!isIDEqual && menuList) {
    menuList.style.height = 0;
  }

  return (
    <div id={id} className={`drop-down-list accordion column ${isIDEqual ? 'active' : ''}`}>
      <header
        style={{ zIndex: 1 }}
        className="row open pointer"
        role="button"
        aria-hidden
        onClick={() => {
          setActiveList(isIDEqual ? null : id);
        }}
      >
        <img className="arrow" src={ARROW_DOWN_SVG} alt="" />
        <h3 className="title">{title}</h3>
      </header>
      <div style={{ height: isIDEqual ? listHeight : 0 }} className="children column menu">
        <ul className={`menu-list ${isIDEqual ? 'animated' : ''}`}>
          {children}
        </ul>
      </div>
    </div>
  );
};

export default Accordion;
