import React from 'react';
import { withTranslation } from 'react-i18next';

const SectionTitle = ({ title, t }) => (
  <h1 className="section-title">
    <span>{t(`${title}.0`)}</span>
    <span>{t(`${title}.1`, '')}</span>
  </h1>
);

export default withTranslation()(SectionTitle);
