import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Login } from './login';
import { Dashboard } from './dashboard';
import { Client } from './client';
import { Loader } from './loader';
import './styles/index.scss';

const App = () => (
  <Suspense fallback={<Loader />}>
    <Switch>
      <Route path="/" component={Client} />

      <Route path="/login" component={Login} exact />

      <Route path="/dashboard" component={Dashboard} />

      <Redirect from="/*" to="/" />
    </Switch>
  </Suspense>
);

export default App;
