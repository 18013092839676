const USERS_REVIEW_LISTS = [
  [{
    text: 'Помогли мне с ремонтом моей субару с Америки. Машина 2019 года. Запчасти найти очень сложно. Особенно кузовные элементы. Но ребята справились, спасибо вам большое! Не знаю как бы я занимался этим сам.',
    author: 'Роман'
  }, {
    text: 'Я звертався за сертифікацією. Все швидко, за 1 день, залишив авто документи, на наступний день забрав автомобіль без жодних проблем. Рекомендую.',
    author: 'Іван'
  }, {
    text: 'Цей сервіс порекомендували друзі яким робили тут ремонт машини з Америки. У мене теж машина з Америки, буває складно знайти запчастини. Справді все знайшли швидко, десь за день. Деякі запчастини були трохи задорогі, я попросив щоб пошукали ще, і через два дні знайшли ще дешевші аналоги. Дякую!!! Заощадив на ремонті.',
    author: 'Юрій'
  }, {
    text: 'Майстри тут з досвідом, нічого не скажеш. Коли перший раз приїхав і зразу це зрозумів. Спочатку як завжди сумніви були. Але при першому спілкуванні всі вони пропали. Задоволений',
    author: 'Євген'
  }], [{
    text: 'У мене претензій немає. Те шо по ремонту і запчастинах обіцяли повністю виконали.',
    author: 'Тарас'
  }, {
    text: 'Ремонт на цьому СТО йшов в комплекті до машини зі штатів. Можна було відмовитися і самому займатися, але я з’їздив на СТО подивився і мені все сподобалося. Тому погодився. Не пошкодував, все ок.',
    author: 'Генадій'
  }, {
    text: 'Це СТО біля мого дому, його знайомі порадили. В мене електромобіль, комп’ютер вибивав помилку, вирішив зайти запитати чи тут таке роблять. Враження залишилося приємне. Все вирішили швидко, додаткові послуги не нав’язували.',
    author: 'Ярослав'
  }, {
    text: 'Ждала пару дней в очереди на ремонт, но я здесь уже не первый раз так что была готова подождать. Знаю что надежный сервис. За очередь руководство извинилось, объяснило что сейчас как раз сезон. Это было очень приятно и профессионально',
    author: 'Катерина'
  }], [{
    text: 'Не хотел заморачиваться ремонтом тачки со Штатов, сразу же покупал её с ремонтом. Сделали всё хорошо, езжу год никаких проблем нет. Следующий ремонт буду делать тоже здесь.',
    author: 'Григорій'
  }, {
    text: 'Заказывала полную дефектовку. Ремонта особо не нужно было, но делали еще химчистку салона, полировку, детейлинг. Машина дорогая, поэтому решила обратится на хороший сервис, посоветовали знакомые. В конце машина как конфетка, не узнать)))) можно фото до и после делать))',
    author: 'Катерина'
  }, {
    text: 'Очень удивился когда готовый автомобиль увидел после ремонта. Приехал он мне очень побитый, но пацаны прям чудо сделали. Вы крутые. Тачку отдали в идеальном состоянии за разумные деньги.',
    author: 'Іван'
  }, {
    text: 'Хорошо что вы есть, ребята..... Купить машину со Штатов сейчас легко. А вот где её обслуживать, вот это вопрос.... Запчасти то не ко всем машинам так просто найти. Очень мне помогаете, всем советую!!!',
    author: 'Сергій'
  }]
];

const USERS_REVIEW_SLIDER_CONFIG = {
  autoplaySpeed: 10000,
  dots: true,
  infinite: true,
  arrows: false,
  speed: 300,
  slidesToScroll: 1,
  autoplay: true,
  slidesToShow: 1
};

const HEADER_SLIDER_CONFIG = {
  autoplaySpeed: 5000,
  dots: true,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToScroll: 1,
  autoplay: false,
  slidesToShow: 1
};

const REPAIR_SLIDER_CONFIG = {
  autoplaySpeed: 5000,
  dots: false,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToScroll: 1,
  autoplay: false,
  slidesToShow: 1
};

export {
  REPAIR_SLIDER_CONFIG,
  USERS_REVIEW_SLIDER_CONFIG,
  HEADER_SLIDER_CONFIG,
  USERS_REVIEW_LISTS
};
