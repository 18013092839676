import React from 'react';
import { useTranslation } from 'react-i18next';

export const LangSwitcher = ({ className = '', closeMenu }) => {
  const { i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    if (closeMenu) {
      closeMenu();
    }
  };

  return (
    <div className={`lang-switcher row ${className}`}>
      <span
        className={i18n.language === 'uk' ? 'enable' : ''}
        aria-hidden
        onClick={() => changeLanguage('uk')}
      >
        укр
      </span>
      <b>|</b>
      <span
        className={i18n.language === 'ru' ? 'enable' : ''}
        aria-hidden
        onClick={() => changeLanguage('ru')}
      >
        рус
      </span>
      {/* <b>|</b>
      <span
        className={i18n.language === 'en' ? 'enable' : ''}
        aria-hidden
        onClick={() => changeLanguage('en')}
      >
        eng
      </span> */}
    </div>
  );
};
